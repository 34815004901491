<template>
  <v-container>
    <v-radio-group
      v-model="inputType"
      row
    >
      <v-radio
        label="Date"
        value="date"
      ></v-radio>
      <v-radio
        label="Username"
        value="username"
      ></v-radio>
    </v-radio-group>
    <v-textarea
      v-if="inputType === 'date'"
      v-model="dates"
      label="Comma Separated Date List"
      outlined
      dense
    />
    <v-text-field
      v-else
      v-model="username"
      label="Phish.net Username"
      outlined
      dense
    />
    <v-btn color="primary" @click="submit" :loading="loading">Submit</v-btn>
    <div v-if="results">
      <v-radio-group
        v-model="streakType"
        row
      >
        <v-radio
          label="Longest Streak"
          value="longest"
        ></v-radio>
        <v-radio
          label="From First Show"
          value="first"
        ></v-radio>
      </v-radio-group>
      <h3 v-if="streakType === 'longest'">Your longest streak of hearing new live songs is <strong>{{ results.streak }}</strong> shows.</h3>
      <h3 v-else>Your streak of hearing new live songs starting from your first show is <strong>{{ results.streak }}</strong> shows.</h3>
      <div style="display: flex; flex-direction: row;">
      <v-sheet
        class="mx-auto"
        elevation="8"
        max-width="800"
        style="overflow-x: auto;"
      >
        <v-slide-group
          v-model="model"
          class="pa-4"
          active-class="success"
          mobile-breakpoint="600"
        >
          <v-slide-item
            :key="index" v-for="day, index in Object.keys(results.path)"
          >
          <v-card
            class="ma-4"
            width="300"
          >
            <div>
              <span>{{ day }}</span>
              <v-list style="width: 300px; height: 500px; overflow-y:scroll;">
                <template v-for="(track, index) in results.path[day]"> 
                  <v-divider :key="index"/>
                  <v-list-item :key="index">
                    {{ track }}
                  </v-list-item>
                </template>
              </v-list>
              </div>
            </v-card>
          </v-slide-item>
        </v-slide-group>
        </v-sheet>
      </div>
    </div>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'HelloWorld',
    data: () => ({
      loading: false,
      resultsRaw: null,
      dates: '',
      inputType: 'date',
      streakType: 'longest',
    }),
    computed: {
      results() {
        if (this.resultsRaw) {
          if (this.streakType === 'longest') {
            return this.resultsRaw.longest;
          } else {
            return this.resultsRaw.first;
          }
        }
        return null;
      },
    },
    mounted() {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('dates')) {
        this.dates =  urlParams.get('dates');
      } else if (urlParams.has('username')) {
        this.username = urlParams.get('username');
        this.inputType = 'username';
      } else {
        this.dates = '2019-12-01,2019-12-03,2019-12-04,2019-12-06,2019-12-07,2019-12-08,2019-12-29,2019-12-30,2019-12-31,2020-02-20,2020-02-21,2020-02-22,2021-07-28';
      }
    },
    methods: {
      async submit() {
        this.loading = true;
        let params = {};
        if (this.inputType === 'date') {
          params = {
            dates: this.dates,
          };
        } else {
          params = {
            username: this.username,
          };
        }
        const response = await axios.get('/streak', { params });
        this.loading = false;
        this.resultsRaw = response.data;
    }
  }
}
</script>
