<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
    >
      <div class="d-flex align-center">
        Phish Streak Checker
      </div>

      <v-spacer></v-spacer>
      <v-btn target="_blank" href="https://twitter.com/MrcoEsquandolas/status/1421144096169775104">
      <div class="d-flex align-center">
        What is this?
      </div>
      </v-btn>
    </v-app-bar>

    <v-main>
      <HelloWorld/>
    </v-main>
    <v-footer>
    <v-col
      class="text-center"
      cols="12"
    >
      Created by
      <a href="https://github.com/vinnybod" target="_blank">
        Vinnybod
      </a> using <a href="https://phish.net" target="_blank">Phish.net's v5 api</a>
    </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

  components: {
    HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>
body {
  background: #e9e9e9;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

span {
  color: #666;
  font-size: 12px;
  display: block;
  position: absolute;
  bottom: 10px;
  position: absolute;
  width: 90%;
  left: 50%;
  top: 45%;
  bottom: auto;
  right: auto;
  transform: translateX(-50%) translateY(-50%);
  text-align: center; }
  span a {
    color: #000000;
    text-decoration: none; }
  span .fa {
    color: #E90606;
    margin: 0 3px;
    font-size: 10px;
    animation: pound .35s infinite alternate;
    -webkit-animation: pound .35s infinite alternate; }

@-webkit-keyframes pound {
  to {
    transform: scale(1.1); } }

@keyframes pound {
  to {
    transform: scale(1.1); } }
</style>